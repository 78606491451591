// eslint-disable-next-line no-shadow
enum Spacings {
  XXS = '4px',
  XS = '8px',
  S = '16px',
  M = '24px',
  L = '32px',
  XL = '40px',
  XXL = '48px',
  XXXL = '64px',
}

export default Spacings;
