export default {
  abnDropDown:
    '0px 8px 16px rgba(34, 34, 34, 0.3), 0px 0px 8px rgba(34, 34, 34, 0.02)',
  abnElevationWell: '0px 2px 2px rgba(34, 34, 34, 0.08)',
  abnElevationTile:
    '0px -2px 4px rgba(34, 34, 34, 0.02), 0px 2px 2px rgba(34, 34, 34, 0.15)',
  abnElevationProduct:
    '0px 0px 8px rgba(34, 34, 34, 0.02), 0px 8px 16px rgba(34, 34, 34, 0.3)',
  abnElevationDropdown:
    '0px 0px 8px rgba(34, 34, 34, 0.02), 0px 8px 16px rgba(34, 34, 34, 0.3)',
  abnElevationCard:
    '0px 0px 9px rgba(34, 34, 34, 0.02), 0px 10px 20px rgba(34, 34, 34, 0.35)',
  abnElevationModal:
    '0px 0px 10px rgba(34, 34, 34, 0.02), 0px 12px 24px rgba(34, 34, 34, 0.4)',
  abnPopover:
    '0px 4px 8px 0px rgba(34, 34, 34, 0.15), 0px 0px 6px 0px rgba(34, 34, 34, 0.02)',
  elevationTextBoxFadeBottom:
    'linear-gradient(0deg, #FFFFFF 20.31%, rgba(255, 255, 255, 0.535948) 69.79%, rgba(255, 255, 255, 0) 100%)',
  elevationTextBoxFadeTop:
    'linear-gradient(180deg, #FFFFFF 20.31%, rgba(255, 255, 255, 0.535948) 69.79%, rgba(255, 255, 255, 0) 100%);',
  abnElevationDialogueModal:
    '0px 4px 8px 0px rgba(0, 0, 0, 0.08), 0px 4px 16px 0px rgba(0, 0, 0, 0.08), 0px 8px 32px 0px rgba(0, 0, 0, 0.08);',
};
