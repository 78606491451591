import React, { PropsWithChildren } from 'react';
import isPropValid from '@emotion/is-prop-valid';
import { StyleSheetManager as StyleSheetManagerStyledComponents } from 'styled-components';

const shouldForwardProp = (propName: string, target: any) =>
  typeof target === 'string' ? isPropValid(propName) : true;

export const StyleSheetManager = ({ children }: PropsWithChildren) => (
  <StyleSheetManagerStyledComponents
    shouldForwardProp={shouldForwardProp}
    enableVendorPrefixes
  >
    {children}
  </StyleSheetManagerStyledComponents>
);
