import elevations from '../elevations';

import {
  primaryButtonBis,
  primaryButtonDefault,
  PrimaryButtonTheme,
  secondaryButtonBis,
  secondaryButtonDefault,
  SecondaryButtonTheme,
} from './buttonTheme';
import { bisColorsTheme, ColorsTheme, defaultColorsTheme } from './colors';
import {
  bisComponentsTheme,
  ComponentsTheme,
  defaultComponentsTheme,
} from './components';
import { bisFontsTheme, defaultFontsTheme, FontsTheme } from './fonts';
import { bisRadiuses, defaultRadiuses, RadiusesTheme } from './radiuses';

interface ThemeElevations {
  abnDropDown: string;
  abnElevationWell: string;
  abnElevationTile: string;
  abnElevationProduct: string;
  abnElevationDropdown: string;
  abnElevationCard: string;
  abnElevationModal: string;
  abnPopover: string;
  elevationTextBoxFadeBottom: string;
  elevationTextBoxFadeTop: string;
}

export enum ThemeName {
  DEFAULT = 'theme-default',
  BIS = 'theme-bis',
}

export interface Theme
  extends FontsTheme,
    ComponentsTheme,
    ColorsTheme,
    PrimaryButtonTheme,
    SecondaryButtonTheme,
    RadiusesTheme {
  name: ThemeName;
  elevations: ThemeElevations;
}

export const defaultTheme: Theme = {
  name: ThemeName.DEFAULT,
  ...defaultFontsTheme,
  ...defaultComponentsTheme,
  ...defaultColorsTheme,
  ...primaryButtonDefault,
  ...secondaryButtonDefault,
  elevations,
  ...defaultRadiuses,
};

export const bisTheme: Theme = {
  name: ThemeName.BIS,
  ...bisFontsTheme,
  ...bisComponentsTheme,
  ...bisColorsTheme,
  ...primaryButtonBis,
  ...secondaryButtonBis,
  elevations,
  ...bisRadiuses,
};
