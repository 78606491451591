import React, { PropsWithChildren } from 'react';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';

import { bisTheme, defaultTheme } from '../../styles/themes';

interface ThemeProviderProps extends PropsWithChildren {
  pathname?: string;
}

const ThemeProvider = ({ children, pathname }: ThemeProviderProps) => {
  const slugs = ['/uw-ondernemingsplan/', '/wegwijzer-voor-starters/'];
  // Keep this variable for now, in case we want to switch back to styling per slug.
  const theme = slugs.includes(pathname || window?.location?.pathname || '')
    ? bisTheme
    : defaultTheme;

  return (
    <StyledComponentsThemeProvider theme={bisTheme}>
      {children}
    </StyledComponentsThemeProvider>
  );
};

export default ThemeProvider;
